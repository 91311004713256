import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const WriteForMePage = () => (
  <Layout
    pageWrapperClassName="college-writing-service-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Custom Essay Writing Service with Reasonable Rates and Top-Notch Papers | TakeAwayEssay.com"
      meta={[
        {
          name: 'description',
          content: 'On a quest for the best custom essay writing service? You’re in the right place! We guarantee paper uniqueness, confidentiality, refunds, revisions, samples, and the best rates. Click here to find out how our company can help you get on the A-list!',
        },

        {
          property: 'og:title',
          content: 'Custom Essay Writing Service with Reasonable Rates and Top-Notch Papers | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'On a quest for the best custom essay writing service? You’re in the right place! We guarantee paper uniqueness, confidentiality, refunds, revisions, samples, and the best rates. Click here to find out how our company can help you get on the A-list!',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/college-writing-service.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Order your paper from
        <br className="first-screen-section__title__br" />
        {' '}
        a custom writing service
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Our experts will complete your assignment according
        <br className="first-screen-section__heading__br" />
        {' '}
        to your demands
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Custom essay writing service that helps you with your academic success
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Studies can be strenuous, but who cares that you’re tired and can’t finish an essay on
            time? Well, we do, that’s why our service offers the best paper help online! Every
            customer has their own reason to use a custom essay writing service. So we ask no
            questions and just deliver custom papers on time.
          </p>
          <p>
            <strong>
              Welcome to TakeAwayEssay.com, a place where professional writing flourishes! On this
              site, you’ll find 532 writers partly from the US who will bring your custom essays to
              new heights for a small price.{' '}
            </strong>
            If you want proof, let us tell you more about our service and don’t forget to check the
            reviews!
          </p>
          <h2 className="h3">
            Custom essays as the best way to improve your writing skills and your grades
          </h2>
          <p>
            Learning takes a lot of time, but catching up with missed lessons takes even more. But
            what if you need an urgent change? That’s where a custom essay writing service comes as
            a savior.
          </p>
          <p>Here’s what you should do:</p>
          <ul className="dot-list">
            <li>Gather all the custom essays you have to write</li>
            <li>Ask your teacher for more</li>
            <li>See what you can write well yourself</li>
            <li>Ask for help with other works from our company</li>
          </ul>
          <p>
            As of April of 2018, we’ve delivered 969,000 pages of custom essay writing. So rest
            assured we can handle all your assignments.
          </p>
          <h2 className="h3">
            Custom essay writing: why we’re the best choice for thousands of students
          </h2>
          <p>
            We are sure our custom essay writing service will help you, so let us prove it with the
            list of our benefits:
          </p>
          <ul className="dot-list">
            <li>
              Plagiarism-free writing
              <br />
              Before delivering a custom essay, we check it with software that finds all the copied
              parts. Aside from direct citations, there are no copied sentences because every paper
              is written from scratch.
            </li>
            <li>
              Quality assurance
              <br />
              Unlike most services, we have a council of quality assurance. These are pros who check
              the papers for any mistakes and irregularities. They also help improve the paper if
              you order a free revision.
            </li>
            <li>
              International help
              <br />
              We write papers for students from the US, Canada, and more! Our
              specialists know everything about adjusting papers for different countries’
              requirements.
            </li>
            <li>
              Confidentiality
              <br />
              We keep your personal information safe. You can find out more about it on our
              Guarantees page.
            </li>
            <li>
              Affordability
              <br />A page of school-level writing is from $10, the undergraduate level is from
              $15, and the master’s level is $25. The rates go higher with the urgency
              of the paper, but we also offer lots of free things.
            </li>
            <li>
              Free stuff
              <br />
              The title and reference pages are free, and so are revisions, formatting (MLA, APA,
              Harvard, Chicago), and samples. If another service tells you these take much time and
              have to be paid for, they just want to charge you extra.
            </li>
            <li>
              Additional features
              <br />
              You can order Powerpoint slides for the paper, writer-specific samples, as well as a
              list of links for used research sources. All these will increase the price a bit, but
              you’ll get the value from them.
            </li>
            <li>
              Urgent deadlines
              <br />
              We deliver papers as soon as 8 hours after getting your order. Choose the deadline by
              clicking on it so we know exactly how soon you need the finished assignment.
            </li>
          </ul>
          <p>If something is unclear, drop a message to our 24/7 customer support.</p>
          <h2 className="h3">Custom essay writing company: choose the most suitable option</h2>
          <p>There are some things a legitimate custom essay writing company should have:</p>
          <ul className="dot-list">
            <li>
              Privacy policy
              <br />
              All your information must be safe with the service of your choice.
            </li>
            <li>
              Secure payments
              <br />
              You have to be sure the system the service is using to keep your finances is safe.
            </li>
            <li>
              Beneficial terms and conditions
              <br />
              If the company works only for its own benefit, you won’t get much out of it.
            </li>
            <li>
              Official registration
              <br />
              The company has to be legal so that you’re sure they won’t scam you.
            </li>
            <li>
              Quick and efficient customer support
              <br />
              You can say a lot about a service by the speed and tone of their support team’s
              replies.
            </li>
          </ul>
          <p>
            Other than that, choose what’s most convenient for you. Prices, deadlines, topics, even
            website design—all these things create your user experience. So choosing an option that
            doesn’t meet one or more of your needs may make that experience not a pleasant one.
          </p>
          <p>
            We sure hope our professional custom essay writing service is just what you need! We
            change and improve to become the most optimal and convenient for any kind of student.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Buy paper">
      Order your paper now and
      <br />
      {' '}
      receive assistance in no time.
    </CTA>

  </Layout>
);

export default WriteForMePage;
